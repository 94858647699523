@import "./variables";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/reboot";
/*@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/root";*/
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/mixins/border-radius";

@include media-breakpoint-up(lg) {
    .center-container {
        width: 50%;
    }
    body {
        margin: 8px auto;
    }
}
body {
    background-color: #e3e4e9;
}
ul.item-list {
    list-style: none;
}
.help-block {
    @extend .alert;
    @extend .alert-warning;
    margin-top: 0.5em;
}
.center-container {
    margin: auto;
    .btn {
        text-align: center;
    }
}
.input-group {
    margin-bottom: 1rem;
}
.btn-center {
    width: $btn-width;
    margin: auto calc((100% - #{$btn-width}) / 2);
}

.status-msg {
    text-align: center;
    background: #246b7a;
    padding: 0.5em 0.1em;
    color: #ece6e6;
    @include border-radius(0.2em);
}
